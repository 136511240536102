import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {SecurityGuard} from './authorisation/authorisation.guard';
import {AuthorisationComponent} from './authorisation/authorisation.component';
import {AppPreLoadingService} from './shared/app-Pre-loading.service';

const routes: Routes = [
  {path: '', redirectTo: 'dashboard', pathMatch: 'full'},
  {path: 'resetPassword', redirectTo: 'account/resetPassword', pathMatch: 'full'},
  {path: 'activate', redirectTo: 'account/activate', pathMatch: 'full'},
  {path: 'login', redirectTo: 'account/login', pathMatch: 'full'},
  {path: 'authorisation/:token', component: AuthorisationComponent},
  {
    path: 'account',
    loadChildren: () => import('./layout/content-layout/content-layout.module').then(m => m.ContentLayoutModule)
  },
  {
    path: '',
    loadChildren: () => import('./layout/content-full-layout/content-full-layout.module').then(m => m.ContentFullLayoutModule),
    canActivate: [SecurityGuard],
    data: {preload: true}
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, preloadingStrategy: AppPreLoadingService, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
