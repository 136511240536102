import {AbstractControl, UntypedFormGroup, ValidationErrors} from '@angular/forms';
import {LocationSelectionModel} from '../models/app.location.model';
import {SpecialityModel} from '../models/app.settings.model';
import {groupBy, GroupResult} from '@progress/kendo-data-query';

export class AppUtils {

    public static checkFormAndDisplayError(formGroup: UntypedFormGroup) {
        for (const controlsKey in formGroup.controls) {
            const ctrl: AbstractControl = formGroup.get(controlsKey);
            if (ctrl.invalid) {
                ctrl.markAsDirty();
            }
        }
    }

    public static isJwtExpired(jwt: any): boolean {
        if (!jwt) {
            return false;
        }
        const current_time = Date.now() / 1000;
        return jwt.exp < current_time;
    }

    public static patternValidator(regex: RegExp, error: ValidationErrors) {
        return (control: AbstractControl): { [key: string]: any } => {
            if (!control.value) {
                return null;
            }

            // test the value of the control against the regexp supplied
            const valid = regex.test(control.value);

            // if true, return no error (no error), else return error passed in the second parameter
            return valid ? null : error;
        };
    }

    static getInteger(value: any) {
        return this.getIntegerDefault(value, null);
    }

    static getIntegerDefault(value: any, defaultVal: number) {
        if (!value) {
            return defaultVal;
        }
        return parseInt(value);
    }

    static getStringDefault(value: any, defaultVal: string) {
        if (!value) {
            return defaultVal;
        }
        return value.toString();
    }

    static getBoolean(val) {
        if (!val) {
            return false;
        }
        return JSON.parse(JSON.stringify(val));
    }

    static refrenceClone(data: any) {
        if (!data) {
            return null;
        }
        return JSON.parse(JSON.stringify(data));
    }

    static isControlValid(formGroup: UntypedFormGroup, ...name: string[]): boolean {
        for (const val of name) {
            if (formGroup.get(val).invalid) {
                return false;
            }
        }
        return true;
    }

    static getBase64StrMimeType(base64: string) {
        if (base64) {
            const firstChar = base64.charAt(0);
            switch (firstChar) {
                case '/':
                    return 'image/jpeg';
                case 'i':
                    return 'image/png';
                case 'R':
                    return 'image/gif';
                case 'U':
                    return 'image/webp';
                case 'J':
                    return 'application/pdf';
                case 'c':
                    return 'application/msword';
                case 'a':
                    return 'text/plain';
            }
        }
        return 'image/jpeg';
    }

    static prepareBase64String(base64: string) {
        return base64 && !base64.startsWith('data:') ? `data:${this.getBase64StrMimeType(base64)};base64,` + base64 : (base64 || '');
    }

    static getPlainBase64str(base64: string) {
        return base64 ? base64.split(',')[1] : '';
    }

    static isValidProviderNumber(value: string) {
        try {
            /**
             *  The Provider Number is comprised of:
             *  Provider Stem - a 6-digit number.
             *  1 Practice Location Character (PLV) - see below
             *  1 Check Digit
             *  The algorithm used for the Provider Check Digit is:
             *  (digit 1 * 3) +
             *  (digit 2 * 5) +
             *  (digit 3 * 8) +
             *  (digit 4 * 4) +
             *  (digit 5 * 2) +
             *  (digit 6) +
             *  (PLV * 6)
             *  Divide the result by 11
             *  The remainder is allocated an alpha that is the provider check digit (See table below for details). */

            const PLV = new Map<string, string>();
            PLV.set('0', '0');
            PLV.set('1', '1');
            PLV.set('2', '2');
            PLV.set('3', '3');
            PLV.set('4', '4');
            PLV.set('5', '5');
            PLV.set('6', '6');
            PLV.set('7', '7');
            PLV.set('8', '8');
            PLV.set('9', '9');
            PLV.set('A', '10');
            PLV.set('B', '11');
            PLV.set('C', '12');
            PLV.set('D', '13');
            PLV.set('E', '14');
            PLV.set('F', '15');
            PLV.set('G', '16');
            PLV.set('H', '17');
            PLV.set('J', '18');
            PLV.set('K', '19');
            PLV.set('L', '20');
            PLV.set('M', '21');
            PLV.set('N', '22');
            PLV.set('P', '23');
            PLV.set('Q', '24');
            PLV.set('R', '25');
            PLV.set('T', '26');
            PLV.set('U', '27');
            PLV.set('V', '28');
            PLV.set('W', '29');
            PLV.set('X', '30');
            PLV.set('Y', '31');

            const checkDigit = new Map<string, string>();
            checkDigit.set('0', 'Y');
            checkDigit.set('1', 'X');
            checkDigit.set('2', 'W');
            checkDigit.set('3', 'T');
            checkDigit.set('4', 'L');
            checkDigit.set('5', 'K');
            checkDigit.set('6', 'J');
            checkDigit.set('7', 'H');
            checkDigit.set('8', 'F');
            checkDigit.set('9', 'B');
            checkDigit.set('10', 'A');

            if (value.length < 8 || value.length > 8) {
                return false;
            }
            if (value.length == 8) {
                if (!/^(\d{7})$/g.test(value.substr(0, 7))) {
                    return false;
                }

                let N = parseInt(value[0]) * 3
                    + parseInt(value[1]) * 5
                    + parseInt(value[2]) * 8
                    + parseInt(value[3]) * 4
                    + parseInt(value[4]) * 2
                    + parseInt(value[5]);
                if (!PLV.has(value[6].toString())) {
                    return false;
                }

                N += parseInt(PLV.get(value[6])) * 6;
                N = N % 11;
                if (!checkDigit.has(N.toString())) {
                    return false;
                }
                if (checkDigit.get(N.toString()).toString() != value[7].toString()) {
                    return false;
                }
            }
            return true;
        } catch (e) {
            return false;
        }
    }

    static isValidAIRProviderNumber(value: string) {
        try {
            /**
             * The Provider Number is comprised of:
             * Provider Stem - a 6-digit number.
             * 1 Practice Location Character (PLV) - see below
             * 1 Check Digit
             * The algorithm used for the Provider Check Digit is:
             * (digit 1 * 3) +
             * (digit 2 * 5) +
             * (digit 3 * 8) +
             * (digit 4 * 4) +
             * (digit 5 * 2) +
             * (digit 6) +
             * (PLV * 6)
             * Divide the result by 11
             * The remainder is allocated an alpha that is the provider check digit (See table below for details).
             */

            const checkDigit = new Map<string, string>();
            checkDigit.set('0', 'Y');
            checkDigit.set('1', 'X');
            checkDigit.set('2', 'W');
            checkDigit.set('3', 'T');
            checkDigit.set('4', 'L');
            checkDigit.set('5', 'K');
            checkDigit.set('6', 'J');
            checkDigit.set('7', 'H');
            checkDigit.set('8', 'F');
            checkDigit.set('9', 'B');
            checkDigit.set('10', 'A');

            const stateDigit = new Map<string, string>();
            stateDigit.set('A', '1');
            stateDigit.set('N', '2');
            stateDigit.set('V', '3');
            stateDigit.set('Q', '4');
            stateDigit.set('S', '5');
            stateDigit.set('W', '6');
            stateDigit.set('T', '7');
            stateDigit.set('Z', '8');
            stateDigit.set('C', '9');
            stateDigit.set('E', '9');

            if (value.length < 7 || value.length > 7) {
                return false;
            }
            if (value.length == 7) {
                for (let i = 1; i < value.length - 2; i++)
                {
                    if (isNaN(parseInt(value[i]))) {
                        return false;
                    }
                }
                const num = value.split('');
                const firstDigit = parseInt(stateDigit.get(num[0]));
                let n = firstDigit * 3
                    + parseInt(num[1].toString()) * 5
                    + parseInt(num[2].toString()) * 8
                    + parseInt(num[3].toString()) * 4
                    + parseInt(num[4].toString()) * 2
                    + parseInt(num[5].toString());
                n %= 11;
                if (!checkDigit.has(n.toString())) {
                    return false;
                }
                if (checkDigit.get(n.toString()) != num[6]) {
                    return false;
                }
            }
            return true;
        } catch {
            return false;
        }
    }

    /**
     * Used to check if object ios empaty or not..!
     * @param obj = 'indecated object which you want to check'
     * return true if empty..!
     */
    static isEmptyObject(obj): boolean {
        return (obj && (Object.keys(obj).length === 0));
    }

    static filteredSpecialities(locations: LocationSelectionModel[], specialities: SpecialityModel[]): GroupResult[] {
        const specialitiesClone = [];
        locations.forEach(location => {
            specialities.forEach((speciality) => {
                const splt = this.refrenceClone(speciality);
                splt['specialityId'] = speciality.id;
                splt['text'] = `${speciality.specialityName}(${location.locationName})`;
                splt['locationId'] = location['locationId'];
                splt['locationName'] = location.locationName;
                splt['uid'] = speciality.id + '_' + location['locationId'];
                specialitiesClone.push(splt);
            });
        });
        return groupBy(specialitiesClone, [{field: 'locationName'}]);
    }

}
