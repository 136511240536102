// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiBaseUrl: 'https://pre-stage-backend.kavanii.com.au',
  // apiBaseUrl: 'http://dev.kavanii.com.au/api',
  recaptchaKey: '6LeI1MImAAAAAKDN2k9v-vneQZCcthL5WIjKt2v3',
  googleApi: 'AIzaSyCK_NpVAl3IL24TXMpJxMO46PXm85TKtq0',
 // stripe: {
   // publicKey: 'pk_test_51L0hJPSHZg0SGcLGVDsOBxLNhsoDmJwpgZBLeGiGFcf92GQk4bswjzO3tkkq1aPN7l5HDxMKIl9dC99RqhFMiIn700QP1FMi8K',
  //}
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
